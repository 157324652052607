<template>
    <v-container fluid :class="$vuetify.breakpoint.mobile ? 'mt-3' : 'mt-6'">
        <team-banner :team="team" @setType="setType" />
        <v-row>
            <v-col cols="12" class="mx-0 px-0">
                <v-tabs
                    v-model="tab"
                    centered
                    class="rounded"
                >
                    <v-tabs-slider class="custom-orange" />
                    <v-tab v-for="(item, index) in items" :key="index">{{ item.name }}</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <team-standings v-if="tab === 0" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :ranking="ranking" :show-filters="false" :matches="matches" :type="type" />
        <roster v-else-if="tab === 1" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :players="team.players.filter(player => player.active)" :color="team.color" />
        <schedule-team v-else-if="tab === 2" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :matches="matches" />
        <team-stats v-else-if="tab === 3" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :players="team.players" :matches-played="team.seasonLoses + team.seasonWins" :type="type" />
    </v-container>
</template>

<script>
    import TeamBanner from '@/components/TeamBanner'
    import Roster from '@/components/Roster'
    import ScheduleTeam from '@/components/ScheduleAlternative'
    import TeamStats from '@/components/TeamStats'
    import teamService from '@/services/team'
    import leagueService from '@/services/league'
    import TeamStandings from '@/components/TeamStandings'
    import matchService from '@/services/match'

    export default {
        components: { TeamBanner, Roster, ScheduleTeam, TeamStats, TeamStandings },
        data() {
            return {
                tab: null,
                items: [
                    {
                        name: 'Κεντρική'
                    },
                    {
                        name: 'Ρόστερ'
                    },
                    {
                        name: 'Παιχνίδια'
                    },
                    {
                        name: 'Στατιστικά'
                    }
                ],
                team: {},
                ranking: [],
                page: 1,
                size: 1000,
                totalPages: 0,
                matches: [],
                type: 'regular_season',
                matchTypes: ['placements', 'regular_season', 'playoffs', 'cup'],
                seasonId: null
            }
        },
        watch: {
            '$route.params.id': {
                handler() {
                    this.getTeam(this.$route.params.id)
                    // this.getRanking(this.$route.params.id)
                    this.fetchMatchesByTeam()
                }
            },
            type: {
                handler() {
                    this.getTeam(this.$route.params.id)
                    // this.getRanking(this.$route.params.id)
                    this.fetchMatchesByTeam()
                }
            }
        },
        created() {
            this.getTeam(this.$route.params.id)
            // this.getRanking(this.$route.params.id)
            this.fetchMatchesByTeam()
        },
        methods: {
            getMatchesPlayed() {
                if (this.type === 'cup') {
                    this.team.seasonLoses = 0
                    this.team.seasonWins = 0
                    this.matches.forEach(element => {
                        if (element.team1.id === parseInt(this.$route.params.id) && element.scoreTeam1 > element.scoreTeam2) {
                            this.team.seasonWins += 1
                        } else if (element.team1.id === parseInt(this.$route.params.id) && element.scoreTeam1 < element.scoreTeam2) {
                            this.team.seasonLoses += 1
                        } else if (element.team2.id === parseInt(this.$route.params.id) && element.scoreTeam2 > element.scoreTeam1) {
                            this.team.seasonWins += 1
                        } else if (element.team2.id === parseInt(this.$route.params.id) && element.scoreTeam2 < element.scoreTeam1) {
                            this.team.seasonLoses += 1
                        }
                    })
                }
            },
            setType(option) {
                this.type = this.matchTypes[option]
            },
            getTeam(teamId) {
                teamService.getTeam(teamId, this.type).then(resp => {
                    this.team = resp.data
                    this.calculateTeamStats()
                    this.getMatchesPlayed()
                })
            },
            getRanking(teamId) {
                teamService.getRankingByTeam(teamId, this.type).then(resp => {
                    this.ranking = resp.data
                    // this.ranking.sort(this.compare)
                })
            },
            getRankingByLeague(leagueId) {
                leagueService.getRankingByLeague(leagueId, this.seasonId, this.type).then(resp => {
                    this.ranking = resp.data
                    // this.ranking.sort(this.compare)
                })
            },
            compare(a, b) {
                if (a.standing < b.standing) {
                    return 1
                }
                if (a.standing > b.standing) {
                    return -1
                }
                if (a.standing === b.standing) {
                    return b.pointsDiff >= a.pointsDiff ? 1 : -1
                }
                return 0
            },
            calculateTeamStats() {
                this.team.players.forEach(player => {
                    player.avg = this.getAverageStats(player)
                    player.cum = this.getCummulativeStats(player)
                })
            },
            getCummulativeStats(player) {
                const stats = {
                    points: 0,
                    rebounds: 0,
                    assists: 0,
                    steals: 0,
                    turnovers: 0,
                    blockedShots: 0,
                    twoPointsMade: 0,
                    twoPointAttempts: 0,
                    threePointsMade: 0,
                    threePointAttempts: 0,
                    freeThrowsMade: 0,
                    freeThrowAttempts: 0,
                    offensiveRebounds: 0,
                    defensiveRebounds: 0,
                    personalFouls: 0
                }
                if (this.type === 'cup') {
                    player.stats = player.stats.filter(element => element.cup)
                }
                player.stats.forEach(element => {
                    for (const key of Object.keys(stats)) {
                        stats[key] += element[key]
                    }
                })
                return stats
            },
            getAverageStats(player) {
                const stats = {
                    points: 0,
                    rebounds: 0,
                    assists: 0,
                    steals: 0,
                    turnovers: 0,
                    blockedShots: 0,
                    twoPointsMade: 0,
                    twoPointAttempts: 0,
                    threePointsMade: 0,
                    threePointAttempts: 0,
                    freeThrowsMade: 0,
                    freeThrowAttempts: 0,
                    offensiveRebounds: 0,
                    defensiveRebounds: 0,
                    personalFouls: 0
                }
                if (this.type === 'cup') {
                    player.stats = player.stats.filter(element => element.cup)
                }
                player.stats.forEach(element => {
                    for (const key of Object.keys(stats)) {
                        stats[key] += element[key]
                    }
                })
                Object.keys(stats).forEach(element => {
                    if (player.stats.length) {
                        stats[element] = stats[element] / player.stats.length
                    } else {
                        stats[element] = 0
                    }
                })
                return stats
            },
            fetchMatchesByTeam() {
                matchService.getMatchesByTeam(this.$route.params.id, null, this.type).then(resp => {
                    this.matches = resp.data
                    this.getMatchesSorted(this.matches)
                    this.getRanking(this.$route.params.id)
                }).catch(error => {
                    if (error.response.data.code === 'MATCH_NOT_FOUND') {
                        this.matches = []
                    }
                })
            },
            getMatchesSorted() {
                this.matches.sort(function(a, b) {
                    if (a.round > b.round) {
                        return 1
                    } else {
                        return -1
                    }
                })
            }
        }
    }
</script>

<style scoped>
.mobile-sticky-banner {
    position: sticky;
    top: 80px;
    z-index: 1;
}

.pc-sticky-banner {
    position: sticky;
    top: 60px;
    z-index: 1;
}

.v-tab {
    text-transform: none !important;
    font-weight: bolder;
    font-size: 15px;
    color: #757578 !important;
}

.v-tab::before {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
}

.v-tab--active {
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 70%, orange 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
